import React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import PageContent from "../page-views/home"
import BgImage from "../assets/images/2017/08/induction-coil-slide.jpg"

const IndexPage = () => {
  const title = "World Leaders in Induction Bending"
  const content =
    "Inductabend has been delivering solid, reliable and creative solutions to its customers across the oil, gas, petrochemical, chemical, mining, water, food and construction industries in Australia, New Zealand, Asia, and abroad."

  return (
    <Layout>
      <Hero title={title} content={content} bgImage={BgImage} />
      <PageContent />
    </Layout>
  )
}

export default IndexPage
