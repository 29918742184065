import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"

const PageContent = () => {
  const articles = [
    {
      title: "WHAT IS INDUCTION BENDING?",
      content:
        "Induction bending is an advanced bending process which utilises electricity to precisely heat and form 2D, 3D, 5D bends and larger.",
      link: "/induction-bending",
    },
    {
      title: "WHAT DO WE DO?",
      content:
        "In addition to induction bending, we provide a wide range of services.",
      link: "/services",
    },
    {
      title: "WHAT CAN WE BEND?",
      content:
        "Induction bending allows the forming of pipe bends, curved structural steel members such as RHS, SHS, CHS, channel, column and beams made from carbon steel, stainless steel, nickel alloys, titanium and more.",
      link: "/services",
    },
  ]

  return (
    <div className="container">
      <div className="page-content">
        <div className="article-group">
          {articles.map(article => (
            <div className="article">
              <h3>{article.title}</h3>
              <p>{article.content}</p>
              <div className="article-action">
                <Link to={article.link}>
                  <FontAwesomeIcon icon={faPlay} />
                  READ MORE
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PageContent
